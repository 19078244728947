import React from "react";
import Layout from "../../components/layout";
import data from "../../data/air-testing-services";
import { Helmet } from "react-helmet";

export default () => {
  const { title, content } = data;
  const { section1, section2 } = content;
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${title} | Clean Air Filter`}</title>
      </Helmet>
      <section>
        <h1>
          <span className="tail">
            <span className="head">{section1.h1.head}</span> {section1.h1.tail}
          </span>
        </h1>
        <article className="w-60">
          <p>{section1.pIntro}</p>
          <p>{section1.p1}</p>
          <ul>
            {data.content.section1.ul.map((item, index) => (
              <li key={index}>
                <p dangerouslySetInnerHTML={{ __html: item }}></p>
              </li>
            ))}
          </ul>
          <p>{section1.p2}</p>
          <p>{section1.p3}</p>
          <p>{section1.p4}</p>
        </article>
      </section>
    </Layout>
  )
};
